html,
body {
    background: #e8eef9 !important;
    height: auto;
    font-family: "DM Sans", sans-serif;
}

.Site {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important; /* 1, 3 */
    min-height: 100vh;
}

.alert-pre {
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
}

.my-space .ui.segment.section {
    background: #e0ecfc;
}

/* List
-----------------------*/
.my-space .ui.list .item {
    padding-bottom: 0.814286em;
    display: flex;
}

.my-space .ui.list > .item > .icon + .content,
.my-space .ui.list > .item > .image + .content {
    padding-top: 0.3em;
}

.my-space .ui.divided.list .list > .item:first-child,
.ui.list > .item:first-child,
ol.ui.list li:first-child,
ul.ui.list li:first-child {
    padding-top: 0em !important;
}

.my-space .ui.divided.list .list > .item:last-child,
.ui.list > .item:last-child,
ol.ui.list li:last-child,
ul.ui.list li:last-child {
    padding-bottom: 0em !important;
}

.my-space .ui.divided.list .item {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}
/* List
-----------------------*/

/* Alerts
-----------------------*/
.label-on-corner {
    top: 0.8em !important;
    left: 78% !important;
}

.alert.ui.message {
    padding: 0.6em 2.5em 0.6em 0.6586em;
    border-radius: 0;
}

    .alert.ui.message .close.icon {
        top: 0.58575em;
        font-size: 1.15rem;
    }

.alert.ui.blue.message {
    background-color: #605ddd;
    color: #fff;
    box-shadow: 0 0 0 1px #605ddd inset, 0 0 0 0 transparent;
}

.alert.ui.red.message {
    background-color: #d11911;
    color: #fff;
    box-shadow: 0 0 0 1px #d11911 inset, 0 0 0 0 transparent;
}

.alert.ui.yellow.message {
    background-color: #f5fb8c;
    color: #36343d;
    box-shadow: 0 0 0 1px #f5fb8c inset, 0 0 0 0 transparent;
}

.alert.ui.red.message a,
.alert.ui.blue.message a {
    color: #f5f5ff;
    font-weight: bold;
}
/* Alerts
-----------------------*/

/* Progress
-----------------------*/
.ui.progress[data-percent="1"] .bar,
.ui.progress[data-percent="2"] .bar,
.ui.progress[data-percent="3"] .bar,
.ui.progress[data-percent="4"] .bar,
.ui.progress[data-percent="5"] .bar,
.ui.progress[data-percent="6"] .bar,
.ui.progress[data-percent="7"] .bar {
    min-width: 50px;
}

.ui.progresx[data-percent="1"] .bar > .progress,
.ui.progresx[data-percent="2"] .bar > .progress,
.ui.progresx[data-percent="3"] .bar > .progress,
.ui.progresx[data-percent="4"] .bar > .progress,
.ui.progresx[data-percent="5"] .bar > .progress,
.ui.progresx[data-percent="6"] .bar > .progress,
.ui.progresx[data-percent="7"] .bar > .progress {
    text-align: right;
}

.ui.progress[data-percent="0"] .bar {
    min-width: 50px;
    background: transparent;
}

    .ui.progress[data-percent="0"] .bar .progress {
        color: rgba(0, 0, 0, 0.87);
    }

.ui.inverted.progress[data-percent="0"] .bar .progress {
    color: rgba(255, 255, 255, 0.9);
}
/* simplified to one setting here, the PR respects possible different colors for each data-percent value */
.ui.inverted.indicating.progress .label {
    color: rgba(255, 255, 255, 0.9);
}

/* Progress
-----------------------*/

/* Very Compact Grid
-----------------------*/
/* .ui.compact.grid {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
} */
.ui.compact.grid > .column:not(.row),
.ui.compact.grid > .row > .column,
.ui.grid > .compact.row > .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.ui.very.compact.grid {
    margin-left: 0;
    /* margin-right: -0.25rem;
    margin-top: -0.25rem;
    margin-bottom: -0.25rem; */
}

    .ui.very.compact.grid > .column:not(.row),
    .ui.very.compact.grid > .row > .column,
    .ui.grid > .very.compact.row > .column {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }

/* Very Compact Grid
-----------------------*/

.my-space .content.card-header {
    background-color: #0a0a39;
    border-radius: 1em 1em 0 0 !important;
    padding: 1.5em 0 1.5em !important;
}

    .my-space .content.card-header .header {
        color: white;
        text-align: center;
        line-height: 2em !important;
        font-size: 1.8em !important;
    }

.my-space .ui.card > .content,
.ui.cards > .card > .content {
    padding: 1.5em 2em 3em;
    box-shadow: none;
}

.rounded-button {
    border-radius: 25px !important;
}

.my-space .rounded-button {
    border-color: #0a0a39 !important;
    color: #0a0a39;
    background-color: transparent;
    border: solid;
}

    .my-space .rounded-button:hover {
        background-color: #0a0a39;
        color: #fff;
        text-shadow: none;
        border: solid;
    }

.my-space .greenCalendar {
    color: #4febd3;
}

.my-space .ui.card,
.ui.cards > .card {
    border-radius: 1em;
    box-shadow: none;
    margin-bottom: 2em;
}

.my-space a {
    color: black !important;
}

    .my-space a.email {
        color: #4949b3 !important;
        text-decoration: underline;
        font-weight: bold;
    }

/*******ui menu*******/

.ui.menu .item .langage-dropdown,
.ui.menu.fixed .item .langage-dropdown {
    border-radius: 30px !important;
    border: 2px solid;
    border-color: #e8eef9;
    height: 20px;
}

    .ui.menu .item .langage-dropdown > .text::after {
        content: "";
        border-left: 2px solid #e8eef9;
        margin: 0 0 0 15px;
        height: 30px;
    }

.ui.menu .item .topMenuAvatar {
    font-size: 14px;
    font-weight: bold;
}

.ui.menu .item.topMenuTitle {
    padding-left: 0em !important;
}

.ui.menu .item.topMenuIcon {
    padding-right: 0em !important;
}

    .ui.menu .item.topMenuIcon::after {
        content: "";
        border-left: 2px solid #e8eef9;
        margin: 0 1em 0 1em;
        height: 100% !important;
        padding: 0em !important;
        margin-top: 0 !important;
    }

/*****Layout footer*****/
.layout-footer {
    /*margin: 5em 0em 0em 0!important;*/
    background-color: #0a0a39 !important;
    padding: 0 0 1em 0 !important;
    flex: none;
}

    .layout-footer .ui.list .item,
    .layout-footer .ui.list a.item {
        color: white;
    }

        .layout-footer .ui.list a.item:hover {
            color: #5076f1 !important;
        }

    .layout-footer .ui.inverted.list a.item {
        color: #5076f1;
    }

    .layout-footer .ui.inverted.list a:hover {
        color: white !important;
    }

    .layout-footer .ui.header {
        color: #5076f1;
    }

    .layout-footer .ui.section.divider {
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: #211f68;
    }

    .layout-footer .last-col-divider {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
        margin-left: -4rem !important;
        margin-right: -33rem !important;
        position: relative;
        background-color: #211f68;
    }

    .layout-footer .vertical-footer-devider {
        left: 0rem !important;
        background-color: #211f68 !important;
    }

    .layout-footer .footer-last-col {
        /*border-left-style: solid;
    border-left-color: #211f68;*/
        padding-left: 4em !important;
    }

    .layout-footer .footer-first-col,
    .layout-footer .footer-second-col {
        margin-right: 2em;
    }

    .layout-footer .footer-third-col {
        border-right-style: solid;
        border-right-color: #211f68;
        margin-bottom: -3rem !important;
    }

        .layout-footer .footer-third-col a {
            font-size: 1.07142857rem;
            font-weight: bold;
        }

        .layout-footer .footer-third-col .list .item {
            margin-bottom: 1em;
        }

    .layout-footer .ui.container {
        padding-top: 0rem;
    }

    .layout-footer .spaced-list .item {
        margin-bottom: 1em;
    }

    .layout-footer .footer-grid {
        margin-top: -5rem;
        margin-bottom: -1rem;
        margin-left: -1rem;
        margin-right: -1rem;
        padding-bottom: 3rem !important;
        /*padding: 0rem;*/
    }

        .layout-footer .footer-grid > .column:not(.row) {
            padding-top: 3em;
            padding-bottom: 1em;
        }

    .layout-footer .rounded-button {
        border-radius: 25px !important;
        background-color: #4848b3;
        color: white;
    }

    .layout-footer .contact-list {
        border-spacing: 0 1em;
    }

        .layout-footer .contact-list > tbody > tr > td:first-child {
            min-width: 3em;
            vertical-align: baseline;
        }

    .layout-footer .copy-right .item {
        color: #aebccd !important;
        border-left: none !important;
        padding-left: 0.15em !important;
        padding-right: 0.15em !important;
    }

    .layout-footer .copy-right a.item {
        text-decoration: underline;
    }

.circular-facebook-icon {
    color: #0a0a39;
    background: #5076f0;
    border-radius: 100%;
    width: 24px !important;
    height: 24px !important;
    font-size: 20px !important;
}

    .circular-facebook-icon::before {
        position: relative;
        top: 5px;
    }

i.blue.icon {
    color: #5076f0 !important;
}

i.icon.rotate {
    transform: rotate(90deg);
    vertical-align: bottom !important;
    padding-left: -1px !important;
}

#root {
    display: flex !important;
    flex-direction: column !important;
    min-height: 100vh !important;
}

.Site-content {
    flex: 1 !important;
}

.dropzone {
    text-align: center;
    background: #fbfbfb;
    padding: 1.7em 0;
    border-top: 2px dashed #267bab;
    cursor: pointer;
    opacity: 0.7;
    transition: 100ms linear;
    border-radius: 0 0 1em 1em !important;
}

    .dropzone:hover {
        opacity: 1;
    }

.ui.menu.top-menu {
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 3em;
    margin-top: 0em;
    padding-top: 1em;
    padding-bottom: 1em;
    transition: box-shadow 0.5s ease, padding 0.5s ease;
    flex: none
}

    .ui.menu.top-menu.fixed {
        background-color: #fff;
        border: 1px solid #ddd;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2)
    }

body.mobile .ui.menu.top-menu {
    margin-bottom: 1em !important;
    padding: 0 !important;
}

    body.mobile .ui.menu.top-menu .item {
        padding: .92857143em 0.7em;
    }

body.mobile .ui.menu .item.topMenuIcon::after {
    border: none;
}

.top-card {
    min-height: 235px;
}

.ui.button.postponementPaymentRequest {
    display: block;
    margin-top: 10px;
}

.stretchedDatepicker {
    width: 100%;
}
